export const cultureList = [
  { id: '1', picturePath: 'http://www.ehanghai.cn/u/cms/www/202001/201354424udq.png', title: '宗旨', content: '发扬工匠精神，整合海洋大数据，打造军民融合的高新技术企业，以“航海更简单，安全有保障”为目标，努力打造国内领先的智慧航保专业服务品牌' },
  { id: '2', picturePath: 'http://www.ehanghai.cn/u/cms/www/202001/201354543e50.png', title: '愿景', content: '建设具有国际影响力的海洋全生态链服务平台' },
  { id: '3', picturePath: 'http://www.ehanghai.cn/u/cms/www/202001/20135505ii2f.png', title: '核心价值观', content: '尊重、远见、效率、创新' },
  { id: '4', picturePath: 'http://www.ehanghai.cn/u/cms/www/202001/201355185fff.png', title: '经营理念', content: '经营知识、整合资源、创造价值、共享收益' }
]
export const teamList = {
  ceo: { id: '1', name: 'xxx', position: '董事长', imgSrc: require('../../assets/image/aochen/team_1.png'), introduce: 'xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx' },
  seat: { id: '2', name: '陈增琦', position: '副总经理', imgSrc: require('../../assets/image/aochen/team_2.png'), introduce: '5年以上涉海平台技术开发经验，精通多种开发语言及数据库，曾负责建设过舟山市应急管理局海上安全监管平台、东保中心“海上高德”海e行智慧版、海上通、航标遥感监测系统等数十个信息化项目，具有丰富的信息化项目建设经验。' },
  pack: { id: '3', name: '周彬翀', position: '总工程师', imgSrc: require('../../assets/image/aochen/team_3.png'), introduce: '毕业于浙江大学，曾参与国家“十二五”专项、全国环境与健康专项工作，并获环保部、卫生部认证数据管理证书，担任多地区功能区划项目负责人，现负责公司电子海图引擎研发。著有论文《河流水生态承载力系统动力学模型软件开发【J】，中国水利水电科学研究院学报》，2020年度中国航海学会科学技术进步奖一等奖。' },
  dgm: { id: '4', name: '王华', position: '经营副总经理', imgSrc: require('../../assets/image/aochen/team_4.png'), introduce: '毕业于西安电子科技大学通信工程专业，具有华为、海上通等知名企业10年以上营销实战经验，有三家大中型规模企业工作经历，其中在两家中大型上市企业担当管理岗位。2014 年 12 月,获得中国人民解放军海军信息化部舰船通信系统改装与保障优秀个人奖。' }
}
