<template>
  <div :class="`${prefixCls}-team`" id="aochenTeam">
    <Separate title="核心团队" desc="TECHNICAL TEAM" class="wow animate__fadeInUp" data-wow-duration="1s"></Separate>
    <div :class="`${prefixCls}-team-core`">
      <div :class="`${prefixCls}-team-core-item`">
<!--        <img :class="`${prefixCls}-team-core-item-img`" :src="teamList.ceo.imgSrc" :alt="teamList.ceo.position">-->
        <div :class="`${prefixCls}-team-core-item-introduce`">
          <p :class="`${prefixCls}-team-core-item-introduce-leftname`">{{teamList.ceo.name}}</p>
          <p :class="`${prefixCls}-team-core-item-introduce-position`">{{teamList.ceo.position}}</p>
          <p :class="`${prefixCls}-team-core-item-introduce-text`">{{teamList.ceo.introduce}}</p>
        </div>
      </div>
<!--      <div :class="`${prefixCls}-team-core-item`"  style="background: #F7F9FA">-->
<!--        <div :class="`${prefixCls}-team-core-item-introduce  ${prefixCls}-team-core-item-right`">-->
<!--          <p :class="`${prefixCls}-team-core-item-introduce-leftname`">{{teamList.seat.name}}</p>-->
<!--          <p :class="`${prefixCls}-team-core-item-introduce-position`">{{teamList.seat.position}}</p>-->
<!--          <p :class="`${prefixCls}-team-core-item-introduce-text`">{{teamList.seat.introduce}}</p>-->
<!--        </div>-->
<!--        <img :class="`${prefixCls}-team-core-item-img`" :src="teamList.seat.imgSrc" :alt="teamList.seat.position">-->
<!--      </div>-->
<!--      <div :class="`${prefixCls}-team-core-item`">-->
<!--        <img :class="`${prefixCls}-team-core-item-img`" :src="teamList.pack.imgSrc" :alt="teamList.pack.position">-->
<!--        <div :class="`${prefixCls}-team-core-item-introduce`">-->
<!--          <p :class="`${prefixCls}-team-core-item-introduce-leftname`">{{teamList.pack.name}}</p>-->
<!--          <p :class="`${prefixCls}-team-core-item-introduce-position`">{{teamList.pack.position}}</p>-->
<!--          <p :class="`${prefixCls}-team-core-item-introduce-text`">{{teamList.pack.introduce}}</p>-->
<!--        </div>-->
<!--      </div>-->
<!--      <div :class="`${prefixCls}-team-core-item`"  style="background: #F7F9FA">-->
<!--        <div :class="`${prefixCls}-team-core-item-introduce  ${prefixCls}-team-core-item-right`">-->
<!--          <p :class="`${prefixCls}-team-core-item-introduce-leftname`">{{teamList.dgm.name}}</p>-->
<!--          <p :class="`${prefixCls}-team-core-item-introduce-position`">{{teamList.dgm.position}}</p>-->
<!--          <p :class="`${prefixCls}-team-core-item-introduce-text`">{{teamList.dgm.introduce}}</p>-->
<!--        </div>-->
<!--        <img :class="`${prefixCls}-team-core-item-img`" :src="teamList.dgm.imgSrc" :alt="teamList.dgm.position">-->
<!--      </div>-->
    </div>
  </div>
</template>

<script>
import Separate from '../../../components/Separate/index'
import { WowMixin } from '@/mixin/wow.js'
import { teamList } from '../data'
export default {
  name: 'AochenTeam',
  data () {
    return {
      prefixCls: this.$prefix + '-company',
      teamList
    }
  },
  mixins: [WowMixin],
  components: {
    Separate
  }
}
</script>

<style lang="scss" scoped>
  $prefixCls: $namespace + -company-team;
  .#{$prefixCls}{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 800px;
    flex-grow: 1;
    &-core{
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
      &-item{
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        width: 100%;
        padding: 100px 0;
        &-introduce{
          padding: 40px 0 40px 40px;
          width: 950px;
          &-rightname {
            font-size: 28px;
            font-weight: bold;
            position: relative;
            padding-bottom: 20px;
            color: #000;
            &::after{
              content: '';
              width: 40px;
              height: 4px;
              background: #B81D23;
              position: absolute;
              right: 0;
              bottom: 0;
            }
          }
          &-leftname {
            font-size: 28px;
            font-weight: bold;
            position: relative;
            padding-bottom: 20px;
            color: #000;
            &::after{
              content: '';
              width: 40px;
              height: 4px;
              background: #B81D23;
              position: absolute;
              left: 0;
              bottom: 0;
            }
          }
          &-position{
            font-size: 20px;
            margin: 40px 0 27px;
          }
          &-text {
            margin-bottom: 10px;
            color: #666666;
            font-size: 14px;
            font-family: Source Han Sans SC;
            line-height: 2;
          }
        }
        &-right {
          display: flex;
          flex-direction: column;
          align-items: flex-end;
        }
        &-img {
          width: 216px;
          height: 318px;
        }
      }
    }
  }
</style>
